import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./SignIn.styles.scss";

import { RootState } from "../../redux/root-reducer";
import {
  resetUser,
  ResetUser,
  setBillingInformation,
  SetCurrentUser,
  setCurrentUser,
  setPaymentEnabled,
  SetPaymentEnabled,
  SetUserBillingInformation,
} from "../../redux/user/user.actions";

import { useAuth0 } from "@auth0/auth0-react";
import {
  UserBillingInformation,
  UserInformation,
} from "../../redux/user/user.types";
import AnimatedLoading from "../../components/AnimatedLoading/AnimatedLoading";
import PaymentService from "../../services/payment.service";
import {
  setAccessToken,
  SetAccessTokenAction,
  setPaymentToken,
  SetPaymentTokenAction,
} from "../../redux/auth/auth.actions";

export const SignIn: React.FC<ISignInProps> = ({
  setPaymentToken,
  setAccessToken,
  setPaymentEnabled,
  setBillingInformation,
  setCurrentUser,
  resetUser,
}: ISignInProps) => {
  const history = useHistory();

  const {
    user,
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    getIdTokenClaims,
  } = useAuth0();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        screen_hint: "signup",
      });
    } else if (!isLoading) {
      signInOAuth();
    }
  }, [isLoading]);

  const signInOAuth = async () => {
    try {
      const claim = await getIdTokenClaims();
      const token = claim?.__raw;

      if (token && user) {
        const userID = claim?.sub.split("|")[1];
        const { given_name, family_name, name, email } = user;

        const fullName = `${given_name} ${family_name}`;
        window.analytics.identify(userID, {
          name: fullName.trim() || name,
          email,
        });
        setCurrentUser({ email });
        setAccessToken(token);
        await getCustomerData();
      } else {
        history.push("/error");
      }
    } catch (error) {
      console.log("Error ", error);
      history.push("/error");
    }
  };

  const getCustomerData = async () => {
    await getPaymentToken();
    return getCustomerProfile();
  };

  const getCustomerProfile = () => {
    return PaymentService.getPaymentProfile()
      .then((res) => res.data)
      .then((res) => {
        const { paymentMethods, addresses } = res;
        const billingInfo = addresses[0];
        const hasPaymentEnabled = paymentMethods.length > 0;
        if (billingInfo) {
          setBillingInformation({ ...billingInfo });
        }
        setPaymentEnabled(hasPaymentEnabled);
        history.push("/onboarding");
      })
      .catch((error) => {
        resetUser();
        if (error.response?.status === 404) {
          history.push("/onboarding");
        } else {
          history.push("/error");
        }
      });
  };

  const getPaymentToken = () => {
    return PaymentService.getPaymentToken()
      .then((res) => res.data)
      .then((res) => {
        const { token } = res;
        setPaymentToken(token);
      })
      .catch((error) => {
        console.log("Error ", error);
      });
  };

  return (
    <section className="sign-in">
      <AnimatedLoading />
    </section>
  );
};

interface IDispatchProps {
  setPaymentToken: (token: string) => SetPaymentTokenAction;
  setAccessToken: (token: string) => SetAccessTokenAction;
  setPaymentEnabled: (enabled: boolean) => SetPaymentEnabled;
  setBillingInformation: (
    billingInfo: UserBillingInformation
  ) => SetUserBillingInformation;
  setCurrentUser: (user: UserInformation) => SetCurrentUser;
  resetUser: () => ResetUser;
}

type Action =
  | SetPaymentTokenAction
  | SetPaymentEnabled
  | SetUserBillingInformation
  | SetCurrentUser
  | ResetUser;

export type ISignInProps = IDispatchProps;

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => ({
  setPaymentToken: (token) => dispatch(setPaymentToken(token)),
  setAccessToken: (token) => dispatch(setAccessToken(token)),
  setPaymentEnabled: (enabled) => dispatch(setPaymentEnabled(enabled)),
  setBillingInformation: (billingInfo) =>
    dispatch(setBillingInformation(billingInfo)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  resetUser: () => dispatch(resetUser()),
});

export default connect<null, IDispatchProps, unknown, RootState>(
  null,
  mapDispatchToProps
)(SignIn);
