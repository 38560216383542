import { LocaleActionTypes } from "./locale.types";
import French from "../../lang/fr.json";
import Portuguese from "../../lang/pt-br.json";
import English from "../../lang/en.json";
import { AppThunk } from "../store";

export interface SetLocale {
  type: typeof LocaleActionTypes.SET_LOCALE;
  payload: string;
}

export interface SetMessages {
  type: typeof LocaleActionTypes.SET_MESSAGES;
  payload: Record<string, string>;
}

export const setLocale = (locale: string): SetLocale => {
  return {
    type: LocaleActionTypes.SET_LOCALE,
    payload: locale,
  };
};

export const setMessages = (messages: Record<string, string>): SetMessages => ({
  type: LocaleActionTypes.SET_MESSAGES,
  payload: messages,
});

export const setMessagesByLocale = (locale: string): AppThunk => {
  const lowerLocale = locale.toLowerCase();
  return (dispatch) => {
    dispatch(setLocale(lowerLocale));
    switch (lowerLocale) {
      case "fr":
        return dispatch(setMessages(French));
      case "pt-br":
        return dispatch(setMessages(Portuguese));
      default:
        return dispatch(setMessages(English));
    }
  };
};
