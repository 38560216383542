import React, { ErrorInfo } from "react";
import ErrorRedirectPage from "../../pages/ErrorRedirect/ErrorRedirect";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_error: Error): State {
    // process the error
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.log(error, info);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorRedirectPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
