import {
  Alert,
  AlertActionCloseButton,
  AlertGroup,
} from "@patternfly/react-core";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../redux/root-reducer";
import { clearToast, ClearToast } from "../../redux/toast/toast.actions";
import { ToastConfig } from "../../redux/toast/toast.types";

export const TOAST_TIMEOUT = 3500;

export const GlobalToast: React.FunctionComponent<GlobalToastProps> = ({
  toastConfig,
  clearToast,
}: GlobalToastProps) => {
  React.useEffect(() => {
    if (toastConfig) {
      const timeoutID = setTimeout(() => {
        clearToast();
      }, TOAST_TIMEOUT);

      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [toastConfig]);

  if (!toastConfig) return null;
  return (
    <AlertGroup isToast>
      <Alert
        variant={toastConfig.variant}
        title={toastConfig.title}
        actionClose={
          <AlertActionCloseButton
            title={toastConfig.title}
            variantLabel={`${toastConfig.variant} alert`}
            onClose={clearToast}
          />
        }
      />
    </AlertGroup>
  );
};

interface IStateProps {
  toastConfig: ToastConfig | undefined;
}

interface IDispatchProps {
  clearToast: () => ClearToast;
}

type Action = ClearToast;

export type GlobalToastProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: RootState): IStateProps => ({
  toastConfig: state.toast.toastConfig,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => ({
  clearToast: () => dispatch(clearToast()),
});

export default connect<IStateProps, IDispatchProps, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(GlobalToast);
