import { createStore, applyMiddleware, Store, AnyAction } from "redux";
import logger from "redux-logger";
import { persistStore, Persistor } from "redux-persist";
import rootReducer, { RootState } from "./root-reducer";
import thunk, { ThunkAction, ThunkMiddleware } from "redux-thunk";

const middlewares: ThunkMiddleware[] = [thunk];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store: Store = createStore(rootReducer, applyMiddleware(...middlewares));
export const persistor: Persistor = persistStore(store);

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export default store;
