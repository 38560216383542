import { AuthActionTypes } from "./auth.types";

export interface SignOutAction {
  type: typeof AuthActionTypes.SIGN_OUT;
  payload: string;
}

export interface SetAccessTokenAction {
  type: typeof AuthActionTypes.SET_ACCESS_TOKEN;
  payload: string;
}

export interface SetPaymentTokenAction {
  type: typeof AuthActionTypes.SET_PAYMENT_TOKEN;
  payload: string;
}

export const setAccessToken = (token: string): SetAccessTokenAction => ({
  type: AuthActionTypes.SET_ACCESS_TOKEN,
  payload: token,
});

export const setPaymentToken = (token: string): SetPaymentTokenAction => ({
  type: AuthActionTypes.SET_PAYMENT_TOKEN,
  payload: token,
});

export const signOut = (): SignOutAction => ({
  type: AuthActionTypes.SIGN_OUT,
  payload: "",
});
