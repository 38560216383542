import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/root-reducer";
import "./PaymentMethodForm.styles.scss";
import {
  BraintreeError,
  Client,
  PayPalCheckout,
  paypalCheckout,
  PayPalTokenizePayload,
} from "braintree-web";
import { AlertVariant, Button, Spinner } from "@patternfly/react-core";
import { PayPalCheckoutTokenizationOptions } from "braintree-web/modules/paypal-checkout";
import { cardPaymentMethodPayload, Dropin } from "braintree-web-drop-in";
import { useHistory } from "react-router-dom";
import {
  setPaymentEnabled,
  SetPaymentEnabled,
} from "../../../redux/user/user.actions";
import { Dispatch } from "redux";
import { FlowType } from "../../../types/paypal.types";
import { translateString } from "../../../util/format";
import { ToastConfig } from "../../../redux/toast/toast.types";
import { setToast, SetToast } from "../../../redux/toast/toast.actions";

const client = require("braintree-web/client");
const dropin = require("braintree-web-drop-in");

export const PaymentMethodForm: React.FunctionComponent<IPaymentMethodFormProps> =
  ({
    userEmail,
    paymentToken,
    method,
    setPaymentEnabled,
    setToast,
  }: IPaymentMethodFormProps) => {
    const history = useHistory();
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [braintreeInstance, setBraintreeInstance] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setIsSaving] = useState<boolean>(false);

    React.useEffect(() => {
      if (method === "paypal") renderPaypalButton();
      else renderDropin();
    }, []);

    const renderPaypalButton = () => {
      client
        .create({
          authorization: paymentToken,
          vaultManager: true,
        })
        .then(function (clientInstance: Client) {
          return paypalCheckout.create({
            client: clientInstance,
          });
        })
        .then(function (paypalCheckoutInstance: PayPalCheckout) {
          return paypalCheckoutInstance.loadPayPalSDK({
            vault: true,
          });
        })
        // PayPal instance type is not consistent with Braintree's SDK type
        .then(function (paypalCheckoutInstance: PayPalCheckout) {
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (window.paypal as any)
            .Buttons({
              //eslint-disable-next-line @typescript-eslint/no-explicit-any
              fundingSource: (window.paypal as any).FUNDING.PAYPAL,
              style: {
                size: "responsive",
                color: "blue",
              },

              createBillingAgreement: function () {
                return paypalCheckoutInstance.createPayment({
                  flow: FlowType.Vault,
                });
              },

              onApprove: function (
                data: PayPalCheckoutTokenizationOptions
                /* _actions: PayPalCheckoutTokenizationOptions */
              ) {
                return paypalCheckoutInstance
                  .tokenizePayment(data)
                  .then((/*payload: AuthorizationResponse*/) => {
                    window.analytics.track("Payment Method - PayPal", {
                      email: userEmail,
                    });
                    setPaymentEnabled(true);
                    history.push("/overview");
                    // Submit `payload.nonce` to server
                  });
              },

              onCancel: function (data: PayPalTokenizePayload) {
                window.analytics.track("Payment Method - PayPal - Cancelled", {
                  email: userEmail,
                });
                console.log("PayPal payment canceled", JSON.stringify(data));
                setToast({
                  variant: AlertVariant.danger,
                  title: translateString("PayPal Payment Canceled"),
                });
              },

              onError: function (err: string) {
                window.analytics.track("Payment Method - PayPal - Error", {
                  error: err,
                  email: userEmail,
                });
                console.error("PayPal error", err);
                setToast({
                  variant: AlertVariant.danger,
                  title: translateString("PayPal Save Error"),
                });
              },
            })
            .render("#paypal-button");
        })
        .then(function () {
          // The PayPal button will be rendered in an html element with the ID
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used
          setLoading(false);
        })
        .catch(function (error: BraintreeError) {
          console.log("PayPal render error ", error);
          setToast({
            variant: AlertVariant.danger,
            title: translateString("PayPal Render Error"),
          });
        });
    };

    const renderDropin = () => {
      dropin
        .create({
          authorization: paymentToken,
          container: "#dropin-container",
          translations: { payingWith: "" },
        })
        .then(function (instance: Dropin) {
          setBraintreeInstance(instance);
          setLoading(false);
        })
        .catch((error: BraintreeError) => {
          console.log("Error ", error);
          setToast({
            variant: AlertVariant.danger,
            title: translateString("Credit Card Render Error"),
          });
        });
    };

    const onSave = () => {
      setIsSaving(true);
      braintreeInstance?.requestPaymentMethod(
        (error: BraintreeError, payload: cardPaymentMethodPayload) => {
          setIsSaving(false);
          if (error) {
            window.analytics.track("Payment Method - Credit Card - Error", {
              error,
              email: userEmail,
            });
            console.log("Error", error);
            setToast({
              variant: AlertVariant.danger,
              title: translateString("Payment Save Error"),
            });
          }
          if (payload.nonce) {
            window.analytics.track("Payment Method - Credit Card", {
              email: userEmail,
            });
            setPaymentEnabled(true);
          }
        }
      );
    };

    return (
      <section className="payment-method-form">
        {paymentToken && (
          <>
            <span className="title gradient">
              {method === "paypal" ? "Pay with PayPal" : "Pay with Credit Card"}
            </span>
            {loading && method === "paypal" && (
              <Spinner isSVG className="spacer" />
            )}
            <div
              id="paypal-button"
              style={{
                display: loading || method !== "paypal" ? "none" : "flex",
              }}
            ></div>
            <div id="dropin-container"></div>
            {method === "credit-card" && !loading && (
              <Button
                variant="primary"
                onClick={onSave}
                id="btn-save"
                isDisabled={saving}
              >
                {translateString("Save")}
              </Button>
            )}
          </>
        )}
      </section>
    );
  };

export type PaymentMethod = "credit-card" | "paypal";

interface IStateProps {
  paymentToken: string;
  userEmail: string;
}

interface IDispatchIProps {
  setPaymentEnabled: (enabled: boolean) => SetPaymentEnabled;
  setToast: (toast: ToastConfig) => SetToast;
}

type Action = SetPaymentEnabled | SetToast;

interface IOwnProps {
  method: PaymentMethod | undefined;
}

export type IPaymentMethodFormProps = IOwnProps & IStateProps & IDispatchIProps;

const mapStateToProps = (state: RootState): IStateProps => ({
  paymentToken: state.auth.paymentToken,
  userEmail: state.user.email,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchIProps => ({
  setPaymentEnabled: (enabled) => dispatch(setPaymentEnabled(enabled)),
  setToast: (toast) => dispatch(setToast(toast)),
});

export default connect<IStateProps, IDispatchIProps, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodForm);
