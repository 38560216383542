import { Button } from "@patternfly/react-core";
import React from "react";
import { translateString } from "../../util/format";

interface OnboardingCardProps {
  title: string;
  content: string;
  contentIcon?: string;
  contentIconAlt?: string;
  action?: () => void;
  actionText?: string;
  actionStyle?: "column" | "row";
  children?: React.ReactNode;
}

const OnboardingCard: React.FunctionComponent<OnboardingCardProps> = ({
  action,
  actionText,
  actionStyle,
  contentIcon,
  contentIconAlt,
  title,
  content,
  children,
}: OnboardingCardProps) => {
  return (
    <section className="content-container text-center">
      <section className="onboarding-info-card pd-small">
        <span className="card-title">{translateString(title)}</span>
        <div className={`card-content ${contentIcon ? "warning" : ""}`}>
          {contentIcon && (
            <img src={contentIcon} alt={contentIconAlt} className="card-icon" />
          )}
          <p>{translateString(content)}</p>
        </div>
        {action && actionText ? (
          <div className={`card-actions ${actionStyle}`}>
            {children}
            <Button
              variant="link"
              className="navigation-button action"
              onClick={action}
            >
              {translateString(actionText)}
            </Button>
          </div>
        ) : (
          children
        )}
      </section>
    </section>
  );
};

export default OnboardingCard;
