export interface AuthState {
  paymentToken: string;
  accessToken: string;
}

export interface AuthActionTypesInterface {  
  SET_ACCESS_TOKEN: string;
  SET_PAYMENT_TOKEN: string;
  SIGN_OUT: string;
}

export const AuthActionTypes:AuthActionTypesInterface = {
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_PAYMENT_TOKEN: "SET_PAYMENT_TOKEN",
  SIGN_OUT: "SIGN_OUT",
}