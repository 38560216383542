import { AuthActionTypes } from "../auth/auth.types";
import { LocaleActionTypes, LocaleState } from "./locale.types";

export const language = navigator.language;

interface ActionType {
  type: string;
  payload: string | Record<string, string>;
}

const INITIAL_STATE: LocaleState = {
  locale: language || "en",
  messages: {},
};

const localeReducer = (
  state: LocaleState = INITIAL_STATE,
  action: ActionType
): LocaleState => {
  switch (action.type) {
    case LocaleActionTypes.SET_LOCALE:
      return { ...state, locale: action.payload as string };
    case LocaleActionTypes.SET_MESSAGES:
      return { ...state, messages: action.payload as Record<string, string> };
    case AuthActionTypes.SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default localeReducer;
