import store from "../redux/store";

export const getAccessToken = (): string => {
  const state = store.getState();
  if (state) {
    const { auth } = state;
    return auth.accessToken;
  }
  return "";
}

export const getLocale = (): string => {
  const state = store.getState();
  if (state) {
    const { user } = state;
    return user.locale;
  }

  return "en";
};

export const getPaymentEnabled = (): boolean => {
  const state = store.getState();
  if (state) {
    const { user } = state;
    return user.paymentEnabled;
  }

  return false;
};
