import { LogoutOptions } from "@auth0/auth0-react";
import React from "react";

export const useSignOut = (
  shouldSignOut: boolean,
  logout: (options?: LogoutOptions) => void
): void => {
  React.useEffect(() => {
    if (shouldSignOut) {
      localStorage.clear();
      logout({ returnTo: window.location.origin });
    }
  }, [shouldSignOut]);
};
