import { AxiosPromise } from "axios";
import api from "./api";

interface IUserService {
  updateUser(
    url: string,
    firstName: string,
    lastName: string,
    countryCodeAlpha2: string,
    streetAddress: string,
    locality: string,
    region: string,
    postalCode: string,
    company?: string,
    extendedAddress?: string,
    email?: string
  ): AxiosPromise;
}

const UserService: IUserService = {
  updateUser(
    url: string,
    firstName: string,
    lastName: string,
    countryCodeAlpha2: string,
    streetAddress: string,
    locality: string,
    region: string,
    postalCode: string,
    company?: string,
    extendedAddress?: string,
    email?: string
  ) {
    const data = {
      firstName,
      lastName,
      countryCodeAlpha2,
      streetAddress,
      locality,
      region,
      postalCode,
      company,
      extendedAddress,
      email,
    };
    return api.put(`${url}/profile`, data);
  },
};

export default UserService;
