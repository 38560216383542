import { ToastActionsTypes, ToastConfig } from "./toast.types";

export interface SetToast {
  type: typeof ToastActionsTypes.SET_TOAST;
  payload: ToastConfig;
}

export interface ClearToast {
  type: typeof ToastActionsTypes.CLEAR_TOAST;
}

export const setToast = (toast: ToastConfig): SetToast => ({
  type: ToastActionsTypes.SET_TOAST,
  payload: toast,
});

export const clearToast = (): ClearToast => ({
  type: ToastActionsTypes.CLEAR_TOAST,
});
