import React from "react";

import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { RootState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  SetLocale,
  SetMessages,
  setMessagesByLocale,
} from "../../redux/locale/locale.actions";
import { language } from "../../redux/locale/locale.reducer";

const cache = createIntlCache();

export let intl = createIntl(
  {
    locale: "en",
    messages: {},
  },
  cache
);

export const LocaleWrapper: React.FC<ILocaleWrapperProps> = ({
  locale,
  children,
  messages,
  setMessagesByLocale,
}: ILocaleWrapperProps) => {
  const userLanguage = language || locale;

  React.useEffect(() => {
    setMessagesByLocale(userLanguage);
  }, []);

  intl = createIntl(
    {
      locale: userLanguage,
      messages,
    },
    cache
  );

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

interface IStateProps {
  locale: string;
  messages: Record<string, string>;
}

interface IDispatchProps {
  setMessagesByLocale: (locale: string) => void;
}

type Action = SetMessages | SetLocale;

interface OwnProps {
  children: React.ReactNode | null;
}

export type ILocaleWrapperProps = IStateProps & IDispatchProps & OwnProps;

const mapStateToProps = (state: RootState): IStateProps => ({
  locale: state.locale.locale,
  messages: state.locale.messages,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): IDispatchProps => ({
  setMessagesByLocale: (locale) => dispatch(setMessagesByLocale(locale)),
});

export default connect<IStateProps, IDispatchProps, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(LocaleWrapper);
