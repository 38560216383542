import React from "react";
import "./OnboardingPage.styles.scss";
import OnboardingHandler from "../../components/OnboardingHandler/OnboardingHandler";

const OnboardingPage: React.FunctionComponent = () => {
  return (
    <section className="onboarding-page">
      <OnboardingHandler />
    </section>
  );
};

export default OnboardingPage;
