import { AuthActionTypes } from "../auth/auth.types";
import { ToastConfig, ToastActionsTypes, ToastState } from "./toast.types";

interface ToastAction {
  type: string;
  payload: ToastConfig | undefined;
}

export const INITIAL_STATE: ToastState = {
  toastConfig: undefined,
};

const toastReducer = (
  state: ToastState = INITIAL_STATE,
  action: ToastAction
): ToastState => {
  switch (action.type) {
    case ToastActionsTypes.SET_TOAST:
      return { ...state, toastConfig: action.payload as ToastConfig };
    case ToastActionsTypes.CLEAR_TOAST:
    case AuthActionTypes.SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default toastReducer;
