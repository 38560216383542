export interface UserState {
  name: string;
  email: string;
  id: string;
  locale: string;
  // Properties below should be refactored to a payment reducer later
  paymentEnabled: boolean;
  billingInfo: UserBillingInformation | undefined;
  orderApproved: boolean;
}

export interface UserInformation {
  name?: string;
  email?: string;
}

export interface UserBillingInformation {
  countryCodeAlpha2: string;
  company: string;
  firstName: string;
  lastName: string;
  locality: string;
  postalCode: string;
  region: string;
  streetAddress: string;
  extendedAddress: string;
}

export interface UserActionTypesInterface {
  SET_CURRENT_USER: string;
  SET_PAYMENT_ENABLED: string;
  SET_USER_ID: string;
  SET_LOCALE: string;
  SET_USER_BILLING_INFORMATION: string;
  SET_ORDER_APPROVAL: string;
  RESET_USER: string;
}

export const UserActionTypes: UserActionTypesInterface = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_PAYMENT_ENABLED: "SET_PAYMENT_ENABLED",
  SET_LOCALE: "SET_LOCALE",
  SET_USER_BILLING_INFORMATION: "SET_USER_BILLING_INFORMATION",
  SET_USER_ID: "SET_USER_ID",
  SET_ORDER_APPROVAL: "SET_ORDER_APPROVAL",
  RESET_USER: "RESET_USER",
};
