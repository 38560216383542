import { AlertVariant } from "@patternfly/react-core";

export interface ToastConfig {
  variant: AlertVariant;
  title: string;
}

export interface ToastState {
  toastConfig: ToastConfig | undefined;
}

export interface ToastActionTypes {
  SET_TOAST: string;
  CLEAR_TOAST: string;
}

export const ToastActionsTypes: ToastActionTypes = {
  SET_TOAST: "SET_TOAST",
  CLEAR_TOAST: "CLEAR_TOAST",
};
