import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/auth.reducer";
import localeReducer from "./locale/locale.reducer";
import toastReducer from "./toast/toast.reducer";
import userReducer from "./user/user.reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["toast"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  locale: localeReducer,
  toast: toastReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(rootPersistConfig, rootReducer);
