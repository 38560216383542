import { intl } from "../components/LocaleWrapper/LocaleWrapper";

export const translateString = (word: string): string => {
  const splittedString = word.split(" ");
  const lowerStrings = splittedString.map((word) => word.toLowerCase());
  const loweredStrings = lowerStrings.join("-");
  const intlString = `app.${loweredStrings}`;

  return intl.formatMessage({
    id: intlString,
    defaultMessage: word,
    description: word,
  });
};
