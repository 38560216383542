import React, { useState } from "react";
import "./OnboardingHandler.styles.scss";
import VEXXHOSTLogo from "../../assets/icons/vexx-logo-colored.svg";
import CreditCardIcon from "../../assets/icons/payment/credit-card.svg";
import PaypalIcon from "../../assets/icons/payment/paypal.svg";
import { AngleLeftIcon, AngleRightIcon } from "@patternfly/react-icons";
import { useAuth0 } from "@auth0/auth0-react";
import BillingForm from "../../components/forms/BillingForm/BillingForm";
import { translateString } from "../../util/format";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { UserBillingInformation } from "../../types/user.types";
import { OnboardingStep } from "../../types/onboarding.types";
import PaymentMethodForm from "../forms/PaymentMethodForm/PaymentMethodForm";
import { PaymentMethod } from "../../types/payment.types";
import { Button, Skeleton, Spinner } from "@patternfly/react-core";
import { RootState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import OnboardingCard from "./OnboardingCard";
import PaymentService from "../../services/payment.service";
import { DASHBOARD_URL } from "../../services/api";
import { Dispatch } from "redux";
import {
  setOrderApproval,
  SetOrderApproval,
} from "../../redux/user/user.actions";
import Reward, { RewardElement } from "react-rewards";
import { useTransition, useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";

export const OnboardingHandler: React.FunctionComponent<IOnboardingHandlerProps> =
  ({
    userEmail,
    billingInfo,
    paymentEnabled,
    orderApproved,
    setOrderApproval,
  }: IOnboardingHandlerProps) => {
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const rewardRef = React.useRef<RewardElement>();

    const { url } = match;
    const [step, setStep] = useState<OnboardingStep>(
      billingInfo ? "payment" : "billing-details"
    );
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [pendingApproval, setPendingApproval] = useState<boolean>(false);
    const [showRedirectingCard, setShowRedirectingCard] =
      useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { logout } = useAuth0();

    const onPaymentMethodClick = (method: PaymentMethod) => {
      setPaymentMethod(method);
    };

    const onSignOut = () => {
      localStorage.clear();
      logout({ returnTo: window.location.origin });
    };

    const onBack = () => {
      if (step === "payment") {
        if (paymentMethod) {
          setPaymentMethod(undefined);
        } else {
          history.push(`${url}/info`);
        }
      } else onSignOut();
    };

    const onNext = () => {
      history.push(`${url}/verification`);
    };

    const showRewards = () => {
      rewardRef?.current?.rewardMe();
    };

    React.useEffect(() => {
      if (paymentEnabled) {
        history.push(`${url}/credentials`);
      } else if (billingInfo && !paymentEnabled) {
        history.push(`${url}/verification`);
      }
    }, []);

    React.useEffect(() => {
      if (
        location.pathname !== `${url}/verification` &&
        location.pathname !== `${url}/info` &&
        location.pathname !== `${url}/credentials`
      ) {
        billingInfo
          ? history.push(`${url}/verification`)
          : history.push(`${url}/info`);
      } else if (paymentEnabled) {
        history.push(`${url}/credentials`);
      } else if (location.pathname.includes("info")) {
        setStep("billing-details");
      } else {
        setStep("payment");
      }
    }, [location.pathname]);

    const getOpenstackOrder = () => {
      return PaymentService.getOpenstackOrder()
        .then(() => {
          if (!orderApproved) {
            window.analytics.track("Order - Approved", { email: userEmail });
            setOrderApproval(true);
            showRewards();
          }
          setShowRedirectingCard(true);
          window.location.replace(DASHBOARD_URL);
        })
        .catch((error) => {
          console.log("Error ", error?.message);
          if (!orderApproved) {
            switch (error?.response?.status) {
              case 402:
                window.analytics.track("Order - Pending", { email: userEmail });
                break;
              case 403:
                window.analytics.track("Order - Fraud", { email: userEmail });
                break;
              default:
                window.analytics.track("Order - Error", {
                  error: error?.message,
                  email: userEmail,
                });
                break;
            }
          }
          setPendingApproval(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    React.useEffect(() => {
      if (paymentEnabled) {
        setIsLoading(true);
        getOpenstackOrder();
      }
    }, [paymentEnabled]);

    const showPaymentOptions =
      !paymentMethod && step === "payment" && !paymentEnabled;
    const showBillingDetails = step === "billing-details" && !paymentEnabled;
    const showPaymentForm =
      paymentMethod && step === "payment" && !paymentEnabled;

    const rewardConfig = {
      springAnimation: false,
      spread: 150,
      startVelocity: 20,
      elementCount: 60,
    };
    const transitions = useTransition(paymentMethod || step, {
      from: { opacity: 0, width: "100%" },
      enter: { opacity: 1 },
      delay: 100,
    });

    const animationProps = useSpring({
      from: { opacity: 0, y: -200 },
      to: { opacity: 1, y: 0 },
      delay: 200,
    });

    return (
      <animated.div style={animationProps} className="onboarding-handler">
        <section
          className="view-container"
          style={{ overflow: showRedirectingCard ? "revert" : "auto" }}
        >
          <div className="onboarding-header">
            <Button
              variant="link"
              icon={<AngleLeftIcon />}
              className="navigation-button"
              onClick={onBack}
              style={{ visibility: paymentEnabled ? "hidden" : "visible" }}
              id={step === "billing-details" ? "btn-sign-out" : "btn-info"}
            >
              {step === "billing-details"
                ? translateString("Sign Out")
                : translateString("Your info")}
            </Button>

            <section className="logo-container">
              <img src={VEXXHOSTLogo} alt="VEXXHOST Logo" />
            </section>

            <Button
              variant="link"
              icon={<AngleRightIcon />}
              iconPosition="right"
              className="navigation-button next"
              onClick={onNext}
              id="btn-next"
              style={{
                visibility:
                  billingInfo && step === "billing-details" && !paymentEnabled
                    ? "visible"
                    : "hidden",
              }}
            >
              {translateString("Verification")}
            </Button>
          </div>
          {transitions((styles, item) =>
            item ? (
              <animated.div style={styles}>
                {showPaymentOptions && (
                  <OnboardingCard
                    title="Verify your identity"
                    content="Verify identity content"
                  >
                    <section className="payment-methods-container">
                      <button
                        className="payment-method"
                        onClick={() => onPaymentMethodClick("credit-card")}
                      >
                        <img src={CreditCardIcon} alt="Credit Card Icon" />
                        {translateString("Credit Card")}
                      </button>

                      <button
                        className="payment-method"
                        onClick={() => onPaymentMethodClick("paypal")}
                      >
                        <img src={PaypalIcon} alt="Paypal Icon" />
                        PayPal
                      </button>
                    </section>
                  </OnboardingCard>
                )}
                {showBillingDetails && (
                  <section className="content-container">
                    <section className="onboarding-info-card">
                      <span className="card-title">
                        {translateString("Getting started")}
                      </span>
                      <p className="card-content">
                        {translateString("Onboarding Billing Content")}
                      </p>
                    </section>
                    <BillingForm />
                  </section>
                )}
                {showPaymentForm && (
                  <PaymentMethodForm method={paymentMethod} />
                )}
                {isLoading && (
                  <div className="loading-container">
                    <Skeleton width="100%" height="100%" />
                  </div>
                )}

                {pendingApproval && (
                  <OnboardingCard
                    title="Fraud Error Title"
                    content="Fraud Error Content"
                    action={onSignOut}
                    actionText="Sign Out"
                  >
                    <a
                      href="https://support.vexxhost.com"
                      target="_blank"
                      rel="noreferrer"
                      className="navigation-button action"
                    >
                      {translateString("Support")}
                    </a>
                  </OnboardingCard>
                )}

                <Reward
                  ref={rewardRef as React.MutableRefObject<RewardElement>}
                  type="confetti"
                  config={rewardConfig}
                >
                  {showRedirectingCard && (
                    <OnboardingCard
                      title="Redirect Card Title"
                      content="Redirect Card Content"
                    >
                      <div className="redirect-loader">
                        <Spinner size="xl" isSVG />
                      </div>
                    </OnboardingCard>
                  )}
                </Reward>
              </animated.div>
            ) : null
          )}
        </section>
      </animated.div>
    );
  };

interface IStateProps {
  billingInfo: UserBillingInformation | undefined;
  paymentEnabled: boolean;
  orderApproved: boolean;
  userEmail: string;
}

interface IDispatchProps {
  setOrderApproval: (approval: boolean) => SetOrderApproval;
}

type Action = SetOrderApproval;

export type IOnboardingHandlerProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: RootState): IStateProps => ({
  billingInfo: state.user.billingInfo,
  paymentEnabled: state.user.paymentEnabled,
  orderApproved: state.user.orderApproved,
  userEmail: state.user.email,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => ({
  setOrderApproval: (approval) => dispatch(setOrderApproval(approval)),
});

export default connect<IStateProps, IDispatchProps, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingHandler);
