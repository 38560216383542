import React, { useState } from "react";
import LoadingAnimation from "../../assets/animation/loading.svg";
import "./AnimatedLoading.styles.scss";

const LAZY_LOADING_DELAY = 250;

const AnimatedLoading: React.FunctionComponent = () => {
  const [show, setShow] = useState<boolean>(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, LAZY_LOADING_DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show ? (
    <article className="animated-loading">
      <object
        type="image/svg+xml"
        data={LoadingAnimation}
        aria-label="Loading"
        className="animation-object"
      >
        <img src={LoadingAnimation} alt="Loading" />
      </object>
    </article>
  ) : null;
};

export default AnimatedLoading;
