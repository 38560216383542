import React from "react";
import "./App.scss";
import "./global.styles.scss";
import Auth0ProviderWithHistory from "./auth/auth-provider-with-history";
import Routes from "./Routes";
import LocaleWrapper from "./components/LocaleWrapper/LocaleWrapper";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import GlobalToast from "./components/GlobalToast/GlobalToast";

const App: React.FunctionComponent = () => {
  return (
    <Auth0ProviderWithHistory>
      <ErrorBoundary>
        <LocaleWrapper>
          <div className="App">
            <Routes />
            <GlobalToast />
          </div>
        </LocaleWrapper>
      </ErrorBoundary>
    </Auth0ProviderWithHistory>
  );
};

export default App;
