import {
  UserActionTypes,
  UserBillingInformation,
  UserInformation,
} from "./user.types";

export interface SetCurrentUser {
  type: typeof UserActionTypes.SET_CURRENT_USER;
  payload: UserInformation;
}

export interface SetLocaleAction {
  type: typeof UserActionTypes.SET_LOCALE;
  payload: string;
}

export interface SetUserId {
  type: typeof UserActionTypes.SET_USER_ID;
  payload: string;
}

export interface SetPaymentEnabled {
  type: typeof UserActionTypes.SET_PAYMENT_ENABLED;
  payload: boolean;
}

export interface SetUserBillingInformation {
  type: typeof UserActionTypes.SET_USER_BILLING_INFORMATION;
  payload: UserBillingInformation;
}

export interface SetOrderApproval {
  type: typeof UserActionTypes.SET_ORDER_APPROVAL;
  payload: boolean;
}

export interface ResetUser {
  type: typeof UserActionTypes.RESET_USER;
}

export const setCurrentUser = (userInfo: UserInformation): SetCurrentUser => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: userInfo,
});

export const setLocale = (locale: string): SetLocaleAction => ({
  type: UserActionTypes.SET_LOCALE,
  payload: locale,
});

export const setPaymentEnabled = (enabled: boolean): SetPaymentEnabled => ({
  type: UserActionTypes.SET_PAYMENT_ENABLED,
  payload: enabled,
});

export const setBillingInformation = (
  billingInformation: UserBillingInformation
): SetUserBillingInformation => ({
  type: UserActionTypes.SET_USER_BILLING_INFORMATION,
  payload: billingInformation,
});

export const setUserID = (id: string): SetUserId => ({
  type: UserActionTypes.SET_USER_ID,
  payload: id,
});

export const setOrderApproval = (approval: boolean): SetOrderApproval => ({
  type: UserActionTypes.SET_ORDER_APPROVAL,
  payload: approval,
});

export const resetUser = (): ResetUser => ({
  type: UserActionTypes.RESET_USER,
});
