import { Button } from "@patternfly/react-core";
import React, { useState } from "react";
import "./ErrorRedirect.styles.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { useSignOut } from "../../hooks/useSignOut";
import { translateString } from "../../util/format";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";

export const ErrorRedirectPage: React.FC = () => {
  const TIMEOUT_SECONDS = 10;
  const TIMEOUT_INTERVAL = 1000;

  const [timeoutCounter, setTimeoutCounter] = useState<number>(TIMEOUT_SECONDS);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const [shouldSignOut, setShouldSignOut] = useState<boolean>(false);

  const { logout } = useAuth0();

  useSignOut(shouldSignOut, logout);

  React.useEffect(() => {
    const intervalID = setInterval(() => {
      setTimeoutCounter((prevState) => prevState - 1);
    }, TIMEOUT_INTERVAL);
    setTimeoutID(intervalID);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  React.useEffect(() => {
    if (timeoutCounter < 1) {
      if (timeoutID) clearInterval(timeoutID);
      signOut();
    }
  }, [timeoutCounter]);

  const signOut = () => {
    window.analytics.reset();
    setShouldSignOut(true);
  };

  const animationProps = useSpring({
    from: { opacity: 0.25 },
    config: { duration: TIMEOUT_INTERVAL },
    loop: { reset: true, opacity: 1 },
  });

  return (
    <article className="error-redirect-page">
      <section className="error-card">
        <section className="error-card-header">
          <h1 className="error-title">{translateString("Error View Title")}</h1>
          <p className="error-subtitle">
            {translateString("Error View Content")}
          </p>
          <p>{translateString("Error Redirection Message")}:</p>
        </section>
        <section className="error-card-content">
          <section className="counter-wrapper">
            <animated.div style={animationProps} className="timeout-counter">
              {timeoutCounter}
            </animated.div>
            <p>{translateString("seconds")}</p>
          </section>
        </section>
        <section className="error-card-footer">
          <p className="footer-text">
            {translateString("Error Redirection Footer Message")}
          </p>
          <div>
            <Button
              variant="primary"
              className="btn-redirect"
              onClick={signOut}
            >
              {translateString("Sign Out")}
            </Button>
          </div>
        </section>
      </section>
    </article>
  );
};

export default ErrorRedirectPage;
