import React from "react";
import { Route, Switch } from "react-router";
import ErrorRedirectPage from "./pages/ErrorRedirect/ErrorRedirect";
import OnboardingPage from "./pages/Onboarding/OnboardingPage";
import SignIn from "./pages/SignIn/SignIn";

const Routes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/onboarding" component={OnboardingPage} />
      <Route path="/error" component={ErrorRedirectPage} />
    </Switch>
  );
};

export default Routes;
