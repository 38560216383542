import axios from "axios";
import { getAccessToken } from "../redux/store.util";

export const USER_API_URL = process.env.REACT_APP_USER_API_URL || "";
export const DASHBOARD_URL =
  "https://auth.vexxhost.net/v3/auth/OS-FEDERATION/identity_providers/default/protocols/openid/websso?origin=https://dashboard.vexxhost.net/auth/websso/";

const api = axios.create({
  timeoutErrorMessage: "Request timed out",
  /* timeout: 5000, */
});

api.interceptors.request.use(
  (config) => {
    const ACCESS_TOKEN = getAccessToken();
    if (ACCESS_TOKEN && config.url?.includes(USER_API_URL)) {
      (config.headers as Record<string, string>)[
        "Authorization"
      ] = `Bearer ${ACCESS_TOKEN}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
