import {
  AlertVariant,
  Button,
  Divider,
  Form,
  Grid,
  GridItem,
  Select,
  SelectOption,
  SelectOptionObject,
  SelectVariant,
  Spinner,
} from "@patternfly/react-core";
import React, { useState } from "react";
import "./BillingForm.styles.scss";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import {
  UserBillingInformation,
  UserInformation,
} from "../../../redux/user/user.types";
import {
  setBillingInformation,
  SetCurrentUser,
  setCurrentUser,
  SetUserBillingInformation,
} from "../../../redux/user/user.actions";
import { RootState } from "../../../redux/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { COUNTRY_LIST } from "../../../util/constants";
import { translateString } from "../../../util/format";
import { AxiosError } from "axios";
import UserService from "../../../services/user.service";
import { USER_API_URL } from "../../../services/api";
import { useHistory, useRouteMatch } from "react-router-dom";
import PaymentService from "../../../services/payment.service";
import {
  setPaymentToken,
  SetPaymentTokenAction,
} from "../../../redux/auth/auth.actions";
import { ToastConfig } from "../../../redux/toast/toast.types";
import { setToast, SetToast } from "../../../redux/toast/toast.actions";

export const BillingForm: React.FunctionComponent<IBillingFormProps> = ({
  billingInfo,
  userEmail,
  setCurrentUser,
  setBillingInformation,
  setPaymentToken,
  setToast,
}: IBillingFormProps) => {
  const extendedAddressRef = React.useRef<HTMLInputElement>(null);
  const [billingData, setBillingData] = useState<IBillingFormData>({
    firstName: billingInfo?.firstName || "",
    lastName: billingInfo?.lastName || "",
    company: billingInfo?.company || "",
    countryCodeAlpha2: billingInfo?.countryCodeAlpha2 || "",
    address: billingInfo?.streetAddress || "",
    extendedAddress: billingInfo?.extendedAddress || "",
    city: billingInfo?.locality || "",
    region: billingInfo?.region || "",
    postalCode: billingInfo?.postalCode || "",
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showBillingFields, setShowBillingFields] = useState<boolean>(
    billingInfo ? true : false
  );
  const [isCountrySelectOpen, setIsCountrySelectOpen] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const history = useHistory();
  const match = useRouteMatch();
  const { url } = match;

  const {
    firstName,
    lastName,
    company,
    countryCodeAlpha2,
    address,
    extendedAddress,
    city,
    region,
    postalCode,
  } = billingData;

  React.useEffect(() => {
    const isInvalid = Object.entries(billingData).some(
      ([name, value]) =>
        name !== "extendedAddress" && name !== "company" && value === ""
    );
    setIsFormValid(!isInvalid);
  }, [billingData]);

  const onToggleCountry = (isOpen: boolean) => {
    setIsCountrySelectOpen(isOpen);
  };

  const handleInputChange = (name: string, value: string) => {
    setBillingData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChange = (
    name: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBillingData((prevData) => ({ ...prevData, [name]: event.target.value }));
  };

  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: google.maps.GeocoderResult[]) => {
        const { address_components } = results[0];
        fillAddress(address_components);
        setShowBillingFields(true);
        extendedAddressRef?.current?.focus();
      })
      .catch((error: AxiosError) => console.error("Error", error));
  };

  const fillAddress = (
    addressComponents: google.maps.GeocoderAddressComponent[]
  ) => {
    let streetNumber = "";
    let address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    addressComponents.forEach((address) => {
      const addressType = address.types[0];
      switch (addressType) {
        case "street_number":
          streetNumber = address.long_name;
          break;

        case "route":
          address1 += address.long_name;
          break;

        case "postal_code":
          postcode = `${address.long_name}${postcode}`;
          break;

        case "administrative_area_level_2":
        case "locality":
          city = city || address.long_name;
          break;

        case "administrative_area_level_1":
          state = address.long_name;
          break;
      }
    });

    setBillingData((prevState) => ({
      ...prevState,
      city,
      address:
        countryCodeAlpha2 === "US" || countryCodeAlpha2 === "CA"
          ? `${streetNumber} ${address1}`
          : `${address1}, ${streetNumber}`,
      postalCode: postcode,
      region: state,
    }));
  };

  const onSelectCountry = (
    _event: React.MouseEvent<Element, MouseEvent> | React.ChangeEvent<Element>,
    selection: string | SelectOptionObject
  ) => {
    setIsCountrySelectOpen(false);
    handleInputChange("countryCodeAlpha2", selection as string);
  };

  const onClearCountry = () => {
    setBillingData((prevState) => ({
      ...prevState,
      countryCodeAlpha2: "",
      address: "",
      extendedAddress: "",
      city: "",
      region: "",
      postalCode: "",
    }));
    setIsCountrySelectOpen(false);
    setShowBillingFields(false);
  };

  const onSave = () => {
    setIsSaving(true);
    UserService.updateUser(
      USER_API_URL,
      firstName,
      lastName,
      countryCodeAlpha2,
      address,
      city,
      region,
      postalCode,
      company,
      extendedAddress,
      userEmail
    )
      .then((res) => res.data)
      .then(() => {
        setBillingInformation({
          countryCodeAlpha2,
          firstName,
          lastName,
          postalCode,
          region,
          streetAddress: address,
          locality: city,
          company,
          extendedAddress,
        });
        setCurrentUser({ name: `${firstName} ${lastName}` });
        window.analytics.track("User Details - Saved", {
          ...billingData,
          email: userEmail,
        });
        return getPaymentToken();
      })
      .catch((error: AxiosError) => {
        setIsSaving(false);
        console.log("Error ", error.response);
        window.analytics.track("User Details - Error", {
          ...billingData,
          email: userEmail,
          error: error?.message,
        });
        setToast({
          variant: AlertVariant.danger,
          title: translateString("Customer Save Error"),
        });
      });
  };

  const getPaymentToken = () => {
    return PaymentService.getPaymentToken()
      .then((res) => res.data)
      .then((res) => {
        const { token } = res;
        setPaymentToken(token);
        history.push(`${url}/verification`);
      })
      .catch((error) => {
        console.log("Error ", error);
      });
  };

  const onEnterAddressManually = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowBillingFields(true);
  };

  return (
    <section className="billing-form">
      <Form className="form-container">
        <h2 className="section-title">{translateString("User Details")}</h2>
        <Grid hasGutter md={6}>
          <div className="form-input-container">
            <label className="required">{translateString("First Name")}</label>
            <input
              className="form-input"
              type="text"
              placeholder={translateString("First Name")}
              id="firstName"
              name="firstName"
              aria-describedby="firstName"
              value={firstName}
              required
              onChange={(event) => handleChange("firstName", event)}
            />
          </div>

          <div className="form-input-container">
            <label className="required">{translateString("Last Name")}</label>
            <input
              className="form-input"
              type="text"
              placeholder={translateString("Last Name")}
              id="last-name"
              name="last-name"
              aria-describedby="last-name"
              value={lastName}
              required
              onChange={(event) => handleChange("lastName", event)}
            />
          </div>

          <GridItem span={12}>
            <div className="form-input-container">
              <label>{translateString("Company")}</label>
              <input
                className="form-input"
                type="text"
                placeholder={translateString("Company")}
                id="company"
                name="company"
                aria-describedby="company"
                value={company}
                onChange={(event) => handleChange("company", event)}
              />
            </div>
          </GridItem>
        </Grid>

        <Divider />

        <h2 className="section-title">{translateString("Billing Address")}</h2>
        <Grid hasGutter md={4} sm={12}>
          <GridItem span={12}>
            <div className="form-input-container">
              <label className="required">{translateString("Country")}</label>
              <Select
                variant={SelectVariant.typeahead}
                typeAheadAriaLabel="Country"
                onToggle={onToggleCountry}
                onSelect={onSelectCountry}
                onClear={onClearCountry}
                selections={countryCodeAlpha2}
                isOpen={isCountrySelectOpen}
                aria-labelledby="countryCodeAlpha2"
                placeholderText={translateString("Country")}
              >
                {COUNTRY_LIST.map((country) => (
                  <SelectOption key={country.code} value={country.code}>
                    {country.value}
                  </SelectOption>
                ))}
              </Select>
            </div>
          </GridItem>

          {countryCodeAlpha2 && (
            <PlacesAutocomplete
              value={address}
              onChange={(value) => handleInputChange("address", value)}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: countryCodeAlpha2 },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <GridItem span={12}>
                  <div className="form-input-container">
                    <label className="required">
                      {translateString("Address Line 1")}
                    </label>
                    <input
                      {...(getInputProps({
                        placeholder: translateString("Address Line 1"),
                        className: "form-input",
                      }) as unknown as Omit<
                        typeof getInputProps,
                        "onKeyDown"
                      > & {
                        onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
                      })}
                    />
                  </div>
                  <div className="autocomplete-dropdown-container">
                    {loading && <Spinner isSVG size="md" />}
                    {[
                      ...suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item active"
                          : "suggestion-item";
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                            key={index}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      }),
                      <button
                        className="suggestion-item"
                        key="manual"
                        style={{
                          display:
                            address && !showBillingFields ? "flex" : "none",
                        }}
                        onClick={onEnterAddressManually}
                      >
                        {translateString("Enter address manually")}
                      </button>,
                    ]}
                  </div>
                </GridItem>
              )}
            </PlacesAutocomplete>
          )}

          {showBillingFields && (
            <>
              <GridItem span={12}>
                <div className="form-input-container">
                  <label>{translateString("Address Line 2")}</label>
                  <input
                    ref={extendedAddressRef}
                    className="form-input"
                    type="text"
                    placeholder={translateString("Address Line 2")}
                    id="extendedAddress"
                    name="extendedAddress"
                    aria-describedby="extendedAddress"
                    value={extendedAddress}
                    required
                    onChange={(event) => handleChange("extendedAddress", event)}
                  />
                </div>
              </GridItem>

              <div className="form-input-container">
                <label className="required">{translateString("City")}</label>
                <input
                  className="form-input"
                  type="text"
                  placeholder={translateString("City")}
                  id="city"
                  name="city"
                  aria-describedby="city"
                  value={city}
                  required
                  onChange={(event) => handleChange("city", event)}
                />
              </div>

              <div className="form-input-container">
                <label className="required">{translateString("Region")}</label>
                <input
                  className="form-input"
                  type="text"
                  placeholder={translateString("Region")}
                  id="region"
                  name="region"
                  aria-describedby="region"
                  value={region}
                  required
                  onChange={(event) => handleChange("region", event)}
                />
              </div>

              <div className="form-input-container">
                <label className="required">
                  {translateString("Postal Code")}
                </label>
                <input
                  className="form-input"
                  type="text"
                  placeholder={translateString("Postal Code")}
                  id="postal-code"
                  name="postal-code"
                  aria-describedby="postal-code"
                  value={postalCode}
                  required
                  onChange={(event) => handleChange("postalCode", event)}
                />
              </div>
            </>
          )}
        </Grid>

        <Button
          variant="primary"
          onClick={onSave}
          isDisabled={!isFormValid || isSaving}
          isLoading={isSaving}
        >
          {translateString("Save")}
        </Button>
      </Form>
    </section>
  );
};

interface IBillingFormData {
  firstName: string;
  lastName: string;
  company: string;
  countryCodeAlpha2: string;
  address: string;
  extendedAddress: string;
  city: string;
  region: string;
  postalCode: string;
}

interface IStateProps {
  userEmail: string;
  billingInfo: UserBillingInformation | undefined;
}

interface IDispatchProps {
  setBillingInformation: (
    billingInfo: UserBillingInformation
  ) => SetUserBillingInformation;
  setCurrentUser: (user: UserInformation) => SetCurrentUser;
  setPaymentToken: (token: string) => SetPaymentTokenAction;
  setToast: (toast: ToastConfig) => SetToast;
}

type Action =
  | SetUserBillingInformation
  | SetCurrentUser
  | SetPaymentTokenAction
  | SetToast;

export type IBillingFormProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: RootState): IStateProps => ({
  userEmail: state.user.email,
  billingInfo: state.user.billingInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): IDispatchProps => ({
  setPaymentToken: (token) => dispatch(setPaymentToken(token)),
  setBillingInformation: (billingInfo) =>
    dispatch(setBillingInformation(billingInfo)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setToast: (toast) => dispatch(setToast(toast)),
});

export default connect<IStateProps, IDispatchProps, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(BillingForm);
