import { AxiosPromise } from "axios";
import { PaymentProfile } from "../types/payment.types";
import api, { USER_API_URL } from "./api";

export interface IPaymentService {
  getPaymentToken(): AxiosPromise;
  getPaymentProfile(): AxiosPromise<PaymentProfile>;
  getOpenstackOrder(): AxiosPromise;
}

const PaymentService: IPaymentService = {
  getPaymentToken(): AxiosPromise {
    return api.get(`${USER_API_URL}/payment/token`);
  },
  getPaymentProfile(): AxiosPromise<PaymentProfile> {
    return api.get(`${USER_API_URL}/payment/profile`);
  },
  getOpenstackOrder(): AxiosPromise {
    return api.get(`${USER_API_URL}/openstack`);
  },
};

export default PaymentService;
