export interface LocaleState {
  locale: string;
  messages: Record<string, string>;
}

export interface LocaleActions {
  SET_LOCALE: string;
  SET_MESSAGES: string;
  SET_MESSAGES_BY_LOCALE: string;
}

export const LocaleActionTypes: LocaleActions = {
  SET_LOCALE: "SET_LOCALE",
  SET_MESSAGES: "SET_MESSAGES",
  SET_MESSAGES_BY_LOCALE: "SET_MESSAGES_BY_LOCALE",
};
