import { RootState } from "../root-reducer";
import { AuthActionTypes, AuthState } from "./auth.types";

interface AuthActionType {
  type: string;
  payload: string | RootState | boolean[];
}

const INITIAL_STATE: AuthState = {
  paymentToken: "",
  accessToken: "",
};

const authReducer = (
  state: AuthState = INITIAL_STATE,
  action: AuthActionType
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload as string };
    case AuthActionTypes.SET_PAYMENT_TOKEN:
      return { ...state, paymentToken: action.payload as string };
    case AuthActionTypes.SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default authReducer;
