import { AuthActionTypes } from "../auth/auth.types";
import {
  UserBillingInformation,
  UserInformation,
  UserState,
  UserActionTypes,
} from "./user.types";

interface ActionType {
  type: string;
  payload: string | boolean | UserBillingInformation | UserInformation;
}

export const INITIAL_STATE: UserState = {
  name: "",
  email: "",
  id: "",
  paymentEnabled: false,
  billingInfo: undefined,
  orderApproved: false,
  locale: "en",
};

const userReducer = (
  state: UserState = INITIAL_STATE,
  action: ActionType
): UserState => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return { ...state, ...(action.payload as UserInformation) };
    case UserActionTypes.SET_PAYMENT_ENABLED:
      return { ...state, paymentEnabled: action.payload as boolean };
    case UserActionTypes.SET_USER_BILLING_INFORMATION:
      return {
        ...state,
        billingInfo: action.payload as UserBillingInformation,
      };
    case UserActionTypes.SET_USER_ID:
      return { ...state, id: action.payload as string };
    case UserActionTypes.SET_LOCALE:
      return { ...state, locale: action.payload as string };
    case UserActionTypes.SET_ORDER_APPROVAL:
      return { ...state, orderApproved: action.payload as boolean };
    case UserActionTypes.RESET_USER:
    case AuthActionTypes.SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userReducer;
